import { GamePlayer, getNextPlayerId } from '../../base/GamePlayer'
import {
  CmdFailure,
  CmdResultEvent,
  CmdResultTypes,
  CmdSuccess,
  DeckCode,
  DeckCodeParam,
  DeckOrder,
  getCard,
  getDeckCode,
  Suit,
  suitEqual,
} from '../../shared'
import {
  calculateScore,
  CompleteRound,
  initRound,
  PlacedBid,
  PlayerScore,
  Round,
} from './round'
import { CompletedTrick, PlayedCard, Trick } from './trick'

// basic state declarations
export interface OhHellState {
  deckCode: DeckCode
  players: GamePlayer[]
  roundsToPlay: number
  completedRounds: CompleteRound[]
  currentRound: Round | undefined
}

export interface OhHellInProgress extends OhHellState {
  currentRound: Round
}

export interface OhHellComplete extends OhHellState {
  currentRound: undefined
}

export function isOhHellState(state: any): state is OhHellState {
  return (
    state &&
    state.deckCode &&
    Array.isArray(state.players) &&
    Array.isArray(state.completedRounds)
  )
}

export function isInProgress(state: OhHellState): state is OhHellInProgress {
  return !!state?.currentRound
}

export enum OhHellCmdType {
  Start = 'START', // note that these are duplicates of basegamecmdtype values... gross
  GetPlayerState = 'GET_PLAYER_STATE',
  DealCards = 'DEAL',
  PlaceBid = 'PLACE_BID',
  PlayCard = 'PLAY_CARD',
  Exit = 'EXIT',
  Kick = 'KICK',
}

export interface OhHellSuccess extends CmdSuccess {
  state: OhHellState
  events: OhHellEvent[]
}
export interface OhHellFailure extends CmdFailure {
  message: OhHellRulesMessage
}

export type OhHellResult = OhHellSuccess | OhHellFailure

export function isSuccess(result: OhHellResult): result is OhHellSuccess {
  return result.result === CmdResultTypes.Success
}

export function ohFail(message: OhHellRulesMessage): OhHellFailure {
  return { result: CmdResultTypes.Failure, message }
}

export function ohSucceed(
  state: OhHellState,
  events: OhHellEvent[] = [],
): OhHellSuccess {
  return { result: CmdResultTypes.Success, state, events }
}

// events - things that happened
export enum OhHellEventName {
  Start = 'START',
  Dealt = 'DEALT',
  BidPlaced = 'BID_PLACED',
  BiddingComplete = 'BIDDING_COMPLETE',
  CardPlayed = 'CARD_PLAYED',
  TrickComplete = 'TRICK_COMPLETE',
  RoundComplete = 'ROUND_COMPLETE',
  EndOfGame = 'END_OF_GAME',
}

export enum OhHellRulesMessage {
  NotDealPhase = 'Game is not in deal phase',
  NotBidPhase = 'Game is not in Bid phase',
  NotPlayPhase = 'Game is not in play phase',
  NotPlayersTurn = `Not player's turn`,
  BidIsNotValid = 'Bid is not valid',
  DealerGetsScrewed = 'Dealer gets screwed',
  CardNotInHand = 'Played card not in hand',
  TrumpsNotBroken = 'Can not lead trump suit until trump suit broken or voided of others',
  MustFollowSuit = 'Played card does not match led suit, but suited card is in hand',
}

export interface OhHellEvent extends CmdResultEvent {
  eventName: OhHellEventName
  details: unknown
}

export interface DealtEvent extends OhHellEvent {
  eventName: OhHellEventName.Dealt
  details: { dealerPlayerId: string }
}
export function dealtEvent(state: OhHellInProgress): DealtEvent {
  return {
    eventName: OhHellEventName.Dealt,
    details: { dealerPlayerId: state.currentRound.dealerPlayerId },
  }
}

export interface BidPlacedEvent extends OhHellEvent {
  eventName: OhHellEventName.BidPlaced
  details: { bid: PlacedBid }
}
export function bidPlacedEvent(state: OhHellInProgress): BidPlacedEvent {
  const bids = state.currentRound.bids
  return {
    eventName: OhHellEventName.BidPlaced,
    details: { bid: bids[bids.length - 1] },
  }
}

export interface BiddingCompleteEvent extends OhHellEvent {
  eventName: OhHellEventName.BiddingComplete
  details: { bids: PlacedBid[] }
}
export function biddingCompleteEvent(
  state: OhHellInProgress,
): BiddingCompleteEvent {
  return {
    eventName: OhHellEventName.BiddingComplete,
    details: { bids: state.currentRound.bids },
  }
}

export interface CardPlayedEvent extends OhHellEvent {
  eventName: OhHellEventName.CardPlayed
  details: { playerCard: PlayedCard }
}
export function cardPlayedEvent(state: OhHellInProgress): CardPlayedEvent {
  if (!state.currentRound?.currentTrick?.cards?.length) {
    throw new Error('expected a card in play')
  }
  const cards = state.currentRound.currentTrick.cards
  return {
    eventName: OhHellEventName.CardPlayed,
    details: { playerCard: cards[cards.length - 1] },
  }
}

export interface TrickCompleteEvent extends OhHellEvent {
  eventName: OhHellEventName.TrickComplete
  details: { trick: Trick }
}
export function trickCompleteEvent(
  state: OhHellInProgress,
): TrickCompleteEvent {
  const tricks = state.currentRound.completedTricks
  return {
    eventName: OhHellEventName.TrickComplete,
    details: { trick: tricks[tricks.length - 1] },
  }
}

export interface RoundCompleteEvent extends OhHellEvent {
  eventName: OhHellEventName.RoundComplete
  details: { score: PlayerScore[] }
}
export function roundCompleteEvent(state: OhHellState): RoundCompleteEvent {
  const score = getScore(state)
  if (!score) {
    throw new Error('expected a score here')
  }
  return {
    eventName: OhHellEventName.RoundComplete,
    details: { score },
  }
}

// module private methods

export function getTrickWinnerId(
  param: DeckCodeParam,
  trick: Trick,
  trumpSuit: Suit | undefined,
): string {
  const deck = getDeckCode(param)
  if (!trick.cards.length) {
    throw new Error('need to be cards in the trick')
  }
  if (trumpSuit) {
    const highestTrumpPlayed = trick.cards
      .filter((playedCard) =>
        suitEqual(getCard(deck, playedCard.card).suit, trumpSuit),
      )
      .reduce(
        (maxPlayerCard: undefined | PlayedCard, nextPlayerCard: PlayedCard) => {
          if (
            !maxPlayerCard ||
            getCard(deck, maxPlayerCard.card).cardValue <
              getCard(deck, nextPlayerCard.card).cardValue
          ) {
            return nextPlayerCard
          }
          return maxPlayerCard
        },
        undefined,
      )
    if (highestTrumpPlayed) {
      return highestTrumpPlayed.playerId
    }
  }

  const leadSuit = getCard(deck, trick.cards[0].card).suit
  const highestSuitedPlayed: PlayedCard =
    trick.cards
      .filter((playedCard) =>
        suitEqual(getCard(deck, playedCard.card).suit, leadSuit),
      )
      .reduce(
        (maxPlayerCard: undefined | PlayedCard, nextPlayerCard: PlayedCard) => {
          if (
            !maxPlayerCard ||
            getCard(deck, maxPlayerCard.card).cardValue <
              getCard(deck, nextPlayerCard.card).cardValue
          ) {
            return nextPlayerCard
          }
          return maxPlayerCard
        },
        undefined,
      ) || trick.cards[0]
  return highestSuitedPlayed.playerId
}

export function closeTrick(state: OhHellInProgress): OhHellInProgress {
  const round: Round = Object.assign({}, state.currentRound)
  const trickToClose = Object.assign({}, state.currentRound.currentTrick)
  const winnerId = getTrickWinnerId(
    state.deckCode,
    trickToClose,
    state.currentRound.trumpSuit,
  )
  const completedTricks: CompletedTrick[] = [
    ...round.completedTricks,
    { cards: trickToClose.cards, winnerId },
  ]
  const updatedBids = round.bids.map((bid) =>
    bid.playerId === winnerId ? { ...bid, wonTricks: bid.wonTricks + 1 } : bid,
  )

  const currentRound: Round = {
    ...round,
    completedTricks,
    turnPlayerId: winnerId,
    currentTrick: { cards: [] },
    bids: updatedBids,
  }

  return {
    ...state,
    currentRound,
  }
}

export function getScore(state: OhHellState): PlayerScore[] | undefined {
  const lastRound = state.completedRounds[state.completedRounds.length - 1]
  if (!lastRound) {
    return undefined
  }
  return lastRound.bids
}

export function closeRound(state: OhHellInProgress): OhHellState {
  const previousScore = getScore(state) || []
  const score: PlayerScore[] = state.currentRound.bids.map((bid) => {
    const bidScore = calculateScore(bid.bidTricks, bid.wonTricks)
    const prevScore = previousScore.find(
      (score) => score.playerId === bid.playerId,
    )
    return {
      ...bid,
      roundScore: bidScore,
      netScore: bidScore + (prevScore?.netScore || 0),
    }
  })
  const prevRound: CompleteRound = {
    ...state.currentRound,
    bids: score,
    currentTrick: undefined,
  }

  const dealerPlayerId = getNextPlayerId(
    prevRound.dealerPlayerId,
    state.players,
  )
  const completedRounds: CompleteRound[] = [...state.completedRounds, prevRound]
  const currentRound: Round | undefined =
    prevRound.roundNumber < state.roundsToPlay
      ? initRound(
          state.deckCode,
          state.players,
          prevRound.roundNumber + 1,
          dealerPlayerId,
        )
      : undefined
  return {
    ...state,
    completedRounds,
    currentRound,
  }
}
