import { ChangeEventHandler, FC, MouseEventHandler, useState } from 'react'
import { OhHellGame } from './shared'
import { AnonGameCmd, KickPlayerCmdData, OhHellCmdType } from '@ohell/games'
import { v4 } from 'uuid'
import {
  ConnectionContext,
  jwtSelector,
} from '../../services/Connections/connectionState'
import { GameSvc } from '../../services/GameSvc'

type HostActionsPropsType = {
  doToggleHost: MouseEventHandler
  game: OhHellGame
}

type HostActionsStateType = {
  selectedKickPlayerId: string
  kickReason: string
}

const getInitialState = (game: OhHellGame) => ({
  selectedKickPlayerId: game.hostUserId,
  kickReason: 'they are tiresome',
})

const HostActions: FC<HostActionsPropsType> = ({ doToggleHost, game }) => {
  const [state, setState] = useState<HostActionsStateType>(
    getInitialState(game),
  )
  const jwt = ConnectionContext.useSelector(jwtSelector)

  const handleChangeselectedKickPlayerId = (value: string) => {
    setState({ ...state, selectedKickPlayerId: value })
  }

  const doRemoveFromGame: MouseEventHandler = (event): void => {
    event.stopPropagation()
    if (state.selectedKickPlayerId === game.hostUserId) {
      alert(`can't kick host`)
      return
    }
    alert(
      `kick player ${state.selectedKickPlayerId} because ${state.kickReason}`,
    )
    sendKickCommand(
      state.selectedKickPlayerId,
      state.kickReason,
      game.gameId,
    ).catch((e) => {
      throw e
    })
  }

  const sendKickCommand = async (
    playerId: string,
    kickReason: string,
    gameId: string,
  ): Promise<void> => {
    const cmdData: KickPlayerCmdData = {
      kickedPlayerId: playerId,
      kickReason: kickReason,
    }
    const cmd: AnonGameCmd = {
      cmdId: v4(),
      gameId: gameId,
      gameRuleSetName: game.gameRuleSetName,
      cmdType: OhHellCmdType.Kick,
      cmdData,
    }
    await GameSvc.sendCmd(cmd, { jwt })
  }

  const updateKickReason: ChangeEventHandler<HTMLInputElement> = (
    event,
  ): void => {
    event.stopPropagation()
    setState({ ...state, kickReason: event.target.value })
  }

  return (
    <div className="bg-classicgray-dark p-2 pb-1 m-1 md:p-6 w-full md:w-10/12 rounded-md flex flex-col justify-center items-center">
      <div className="w-10/12 h-70v md:h-70v p-2 m-1 text-left overflow-y-auto md:overflow-y-scroll scrollbar flex-col">
        <div>
          <h3>Host Actions.</h3>
        </div>
        <div className="flex-row">
          <span>kick </span>
          <select
            value={state.selectedKickPlayerId}
            name="selectedKickPlayer"
            onChange={(e) =>
              handleChangeselectedKickPlayerId(e.target.value as string)
            }
          >
            {game.players.map((player) => (
              <option value={player.userId}>{player.handle}</option>
            ))}
          </select>
          <span> because </span>
          <input defaultValue={state.kickReason} onChange={updateKickReason} />
          <button
            onClick={doRemoveFromGame}
            className="m-1 px-2 border border-black"
          >
            remove from game
          </button>
        </div>
      </div>
      <div className="w-6/12 p-2 flex justify-center">
        <button className="p-1 m-1 text-xs" onClick={doToggleHost}>
          exit
        </button>
      </div>
    </div>
  )
}

export default HostActions
