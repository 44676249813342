import { KickPlayerCmdData } from '../../base/Game'
import { GamePlayer } from '../../base/GamePlayer'
import { OhHellResult, OhHellState, ohSucceed } from '../state/state'

export function kickPlayer(
  state: OhHellState,
  kickData: KickPlayerCmdData,
): OhHellResult {
  const exitingUserId = kickData.kickedPlayerId
  const players = buildPlayersWithExitedPlayer(
    state,
    exitingUserId,
    kickData.kickReason,
  )
  const kickedState: OhHellState = {
    ...state,
    players,
  }
  return ohSucceed(kickedState, [])
}

const buildPlayersWithExitedPlayer = (
  state: OhHellState,
  exitingPlayerId: string,
  kickReason: string | null,
): GamePlayer[] => {
  const players = [...state.players]
  const exitingPlayerIndex = players.findIndex(
    (player) => player.userId === exitingPlayerId,
  )
  if (exitingPlayerIndex !== -1) {
    const exitedPlayer: GamePlayer = {
      ...players[exitingPlayerIndex],
      didExit: true,
      kickReason: kickReason || null,
    }
    players.splice(exitingPlayerIndex, 1, exitedPlayer)
  }
  return players
}
