import { Pronouns } from '@ohell/common'

export type GamePlayer = {
  userId: string
  handle: string
  pronouns: Pronouns
  didExit: boolean
  kickReason: string | null
  computerPlayerId: string | null
}

export interface GamePlayerOptions {
  userId: string
  handle: string
  pronouns?: Pronouns
  didExit?: boolean
  kickReason?: string
  computerPlayerId?: string | null
}

export function buildGamePlayer(options: GamePlayerOptions): GamePlayer {
  return {
    userId: options?.userId,
    handle: options?.handle,
    pronouns: options?.pronouns || Pronouns.They,
    didExit: options?.didExit || false,
    kickReason: options?.kickReason || null,
    computerPlayerId: options?.computerPlayerId || null,
  }
}

export function isGamePlayer(item: GamePlayer | unknown): item is GamePlayer {
  const gamePlayer = item as GamePlayer
  return !!(gamePlayer.userId && gamePlayer.handle)
}

export function isActiveGamePlayer(player: GamePlayer) {
  return !player.didExit
}

export function getNextPlayerId(
  currentPlayerId: string,
  players: GamePlayer[],
): string {
  const currentPlayerIndex = players.findIndex(
    (player) => player.userId === currentPlayerId,
  )
  if (currentPlayerIndex === -1) {
    throw new Error('currentPlayerId not in players in getNextPlayer')
  }
  let nextIndex = (currentPlayerIndex + 1) % players.length
  while (nextIndex !== currentPlayerIndex) {
    if (isActiveGamePlayer(players[nextIndex])) {
      return players[nextIndex].userId
    }
    nextIndex = (nextIndex + 1) % players.length
  }
  throw new Error('could not find active player for next turn')
}
