import { GameStatus } from '@ohell/games'
import { FC, MouseEventHandler } from 'react'
import { getNumberOrDash } from './shared'

export type RosterPlayer = {
  handle: string
  isComputer: boolean
  computerPlayerId: string | null
  isHost: boolean
  userId: string
  didExit: boolean
  kickReason: string | null
  isPlayerTurn?: boolean
  tricksBid?: number | undefined
  tricksWon?: number | undefined
  netScore?: number | undefined
}

export type RosterProps = {
  isUserHost: boolean
  players: RosterPlayer[]
  status: string
  doToggleHelp: MouseEventHandler
  doToggleHost: MouseEventHandler
}

const getComputerIndicator = (player: RosterPlayer) => {
  return player.computerPlayerId || player.isComputer ? (
    <span role="img" aria-label="computer player">
      🖥️
    </span>
  ) : (
    <span role="img" aria-label="human player">
      ⚇
    </span>
  )
}

const Roster: FC<RosterProps> = ({
  isUserHost,
  players,
  status,
  doToggleHelp,
  doToggleHost,
}: RosterProps) => {
  const getPlayerClassname = (player: RosterPlayer): string => {
    if (player.kickReason) {
      return 'line-through'
    }
    if (player.didExit) {
      return 'line-through'
    }
    if (player.isPlayerTurn) {
      return 'font-bold'
    }
    return ''
  }

  return (
    <div className="bg-classicgray-dark p-2 pb-1 md:p-6 m-1 w-full md:w-2/12 max-w-sm rounded-md flex flex-col">
      <h3>players</h3>
      <div>
        {players?.map((player, index) => (
          <div className="flex-col" key={`rosterKey${index}`}>
            <div className="flex">
              <div className="w-7 text-center">
                {getComputerIndicator(player)}
              </div>
              <div
                className={getPlayerClassname(player)}
              >{`${player.handle}${player.isHost ? ' (host)' : ''}`}</div>
            </div>
            {status === GameStatus.InProgress ? (
              <div className="ml-5 h-5 text-xs">
                {`bid  ${getNumberOrDash(player.tricksBid)}`} /{' '}
                {`won  ${getNumberOrDash(player.tricksWon)} `}
                {(player.netScore || player.netScore === 0) &&
                  ` score ${player.netScore}`}
              </div>
            ) : (
              <div className="h-5"></div>
            )}
          </div>
        )) || <div>no players to display</div>}
      </div>
      <div className="flex-1 flex flex-col-reverse">
        <div className="flex justify-center">
          <button className="p-1 m-1 text-xs" onClick={doToggleHelp}>
            show help
          </button>
        </div>
        {isUserHost && (
          <div className="flex justify-center">
            <button className="p-1 m-1 text-xs" onClick={doToggleHost}>
              host actions
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default Roster
