import { v4 } from 'uuid'
import {
  Game,
  GameCmd,
  GameCmdHandler,
  GameCmdResult,
  GameEvent,
  KickPlayerCmdData,
} from '../base/Game'
import { isCardRef } from '../shared'
import { dealCards } from './commands/dealCards'
import { defaultOptions, init, OhHellOptions } from './commands/init'
import { placeBid } from './commands/placeBid'
import { playCard, choosePlayableCards } from './commands/playCard'
import {
  getOhHellPlayerState,
  OhHellPlayerState,
  PlayerRound,
} from './state/playerState'
import { Bid, CompleteRound, Deal, PlacedBid, PlayerScore } from './state/round'
import {
  isSuccess,
  OhHellEvent,
  OhHellEventName,
  OhHellInProgress,
  OhHellResult,
  OhHellState,
  OhHellCmdType,
  getTrickWinnerId,
} from './state/state'
import { CompletedTrick, PlayedCard, Trick } from './state/trick'
import { kickPlayer } from './commands/kickPlayer'

export { ExpertIds, ExpertPlayer } from './expert/shared'
export { OhHellPlayerState, PlayerRound, CompleteRound, Trick, CompletedTrick }
export { getTrickWinnerId, choosePlayableCards }
export { OhHellCmdType }
export { Bid, Deal, PlayedCard, PlacedBid, PlayerScore }

const getGameEvent = (game: Game, event: OhHellEvent): GameEvent => ({
  eventId: v4(),
  gameId: game.gameId,
  eventType: event.eventName,
  eventData: event.details,
})

const handleOhHellResult = (
  game: Game,
  result: OhHellResult,
): GameCmdResult => {
  if (isSuccess(result)) {
    return {
      game: { ...game, gameState: result.state },
      events: result.events.map((event) => getGameEvent(game, event)),
    }
  }
  throw new Error(result.message)
}

export const OhHellHandlers: Record<string, GameCmdHandler> = {
  start: (prevGame: Game, cmd: GameCmd): GameCmdResult => {
    const cmdData = (cmd.cmdData || {}) as {}
    const options = { ...defaultOptions, ...cmdData } as OhHellOptions
    if (!options.deckCode || !options.rounds) {
      throw new Error('invalid options for start OhHell')
    }
    const gameState: OhHellInProgress = init(
      prevGame.players,
      prevGame.hostUserId,
      options,
    )
    const startEvent = getGameEvent(prevGame, {
      eventName: OhHellEventName.Start,
      details: {},
    })
    return {
      game: { ...prevGame, gameState },
      events: [startEvent],
    }
  },
  getPlayerState: (prevGame: Game, cmd: GameCmd) => {
    if (!cmd?.player?.userId) {
      throw new Error('must provide player to getPlayerState in cmd')
    }
    const playerId = cmd.player.userId
    const state = prevGame.gameState as OhHellState | undefined
    if (!state?.currentRound || !state?.currentRound?.hands?.length) {
      return { game: { ...prevGame, gameState: state }, events: [] }
    }
    const ohHellState = getOhHellPlayerState(state, playerId)
    return { game: { ...prevGame, gameState: ohHellState }, events: [] }
  },
  dealCards: (prevGame: Game, cmd: GameCmd): GameCmdResult => {
    const deal = cmd?.cmdData as Deal
    if (!deal?.playerId) {
      throw new Error(
        `missing deal data in DealCards; cmd was ${JSON.stringify(cmd)} `,
      )
    }
    const result = dealCards(prevGame.gameState as OhHellState, deal)
    return handleOhHellResult(prevGame, result)
  },
  placeBid: (prevGame: Game, cmd: GameCmd): GameCmdResult => {
    const bid = cmd?.cmdData as Bid
    if (!bid || (!bid.bidTricks && bid.bidTricks !== 0) || !bid.playerId) {
      throw new Error(
        `missing bid data in placeBid; cmd was ${JSON.stringify(cmd)} `,
      )
    }
    const result = placeBid(prevGame.gameState as OhHellState, bid)
    return handleOhHellResult(prevGame, result)
  },
  playCard: (prevGame: Game, cmd: GameCmd): GameCmdResult => {
    const card = cmd?.cmdData as PlayedCard
    if (!card || !isCardRef(card.card) || !card.playerId) {
      throw new Error(
        `missing card data in playCard; cmd was ${JSON.stringify(cmd)} `,
      )
    }
    const result = playCard(prevGame.gameState as OhHellState, card)
    return handleOhHellResult(prevGame, result)
  },
  kickPlayer: (prevGame: Game, cmd: GameCmd): GameCmdResult => {
    const kickData = cmd.cmdData as KickPlayerCmdData

    if (!kickData) {
      throw new Error(
        `missing kick player data in kickPlayer; cmd was ${JSON.stringify(
          cmd,
        )} `,
      )
    }
    const result = kickPlayer(prevGame.gameState as OhHellState, kickData)
    return handleOhHellResult(prevGame, result)
  },
}
