import { GameStatus } from './GameState'
import { v4 } from 'uuid'
import { buildGamePlayer, GamePlayer, GamePlayerOptions } from './GamePlayer'
import { GameRuleSets } from '../shared'
import { Pronouns } from '@ohell/common'
import { OhHellPlayerState } from '../ohHell/OhHell'

export interface Game {
  gameId: string
  status: GameStatus
  hostUserId: string
  hostHandle: string
  createDate: Date
  gameRuleSetName: GameRuleSets
  minPlayers: number
  maxPlayers: number
  players: Array<GamePlayer>
  gameState: unknown
  gameChatroomId: string
}

export interface AnonGameCmd {
  cmdId: string
  gameId: string
  gameRuleSetName: string
  cmdType: BaseGameCmdTypes | string
  cmdData: unknown
  onBehalfOfPlayerId?: string
}

export interface AnonCreateGameCmd {
  cmdId: string
  gameRuleSetName: string
  cmdType: BaseGameCmdTypes | string
  cmdData: unknown
}

export interface GameCmd extends AnonGameCmd {
  player: GamePlayer
}

export interface GameEvent {
  eventId: string
  gameId: string
  gameRuleSetName?: string
  eventType: BaseGameCmdTypes | string
  eventData: any
}

export interface GameCmdResult {
  game: Game | undefined
  events: GameEvent[]
}

export type GameCmdHandler = (prevGame: Game, cmd: GameCmd) => GameCmdResult

export interface buildCmdOptions {
  cmdId?: string
  gameId?: string
  gameRuleSetName: string
  cmdType: string
  cmdData: unknown
}

export function buildGameCmd(
  options: AnonGameCmd,
  gamePlayerOptions: GamePlayerOptions,
): GameCmd {
  if (!options.gameRuleSetName) {
    throw new Error('No game ruleset suggested')
  }
  if (!options.cmdType) {
    throw new Error('No game command suggested')
  }
  if (!gamePlayerOptions) {
    throw new Error('No game command player provided')
  }
  return {
    cmdId: options?.cmdId || v4(),
    gameId: options?.gameId || '',
    gameRuleSetName: options.gameRuleSetName,
    cmdType: options?.cmdType,
    player: buildGamePlayer({ ...gamePlayerOptions }),
    cmdData: options?.cmdData || {},
  }
}

export function isCmdType<T extends { [k: string]: string }>(
  cmdTypeString: string | null | undefined,
  cmdEnum: T,
): cmdTypeString is T[keyof T] {
  return !!(cmdTypeString && Object.values(cmdEnum).includes(cmdTypeString))
}

export enum BaseGameCmdTypes {
  Create = 'CREATE',
  Start = 'START',
  Join = 'JOIN',
  GetPlayerState = 'GET_PLAYER_STATE',
  Exit = 'EXIT',
  Error = 'ERROR',
  Kick = 'KICK',
}

const createGame: GameCmdHandler = (_prevGame: Game, cmd: GameCmd) => {
  throw new Error('not implemented')
}

export type JoinComputerPlayerCmdData = {
  handle: string
  computerPlayerId: string
}

const joinGame: GameCmdHandler = (prevGame: Game, cmd: GameCmd) => {
  const cmdData = cmd.cmdData as JoinComputerPlayerCmdData | undefined

  const buildComputerPlayer = (cmd: GameCmd): GamePlayer => {
    const data = (cmd.cmdData || {}) as JoinComputerPlayerCmdData
    if (!data.handle) {
      throw new Error('must provide handle for computer player in cmdData')
    }
    if (!data.computerPlayerId) {
      throw new Error(
        'must provide computerPlayerId for computer player in cmdData',
      )
    }
    return buildGamePlayer({
      userId: v4(),
      handle: data.handle,
      pronouns: Pronouns.They,
      computerPlayerId: data.computerPlayerId,
      didExit: false,
    })
  }
  const buildHumanPlayer = (cmd: GameCmd): GamePlayer =>
    buildGamePlayer({
      userId: cmd.player.userId,
      handle: cmd.player.handle,
      pronouns: cmd.player.pronouns,
    })

  const player = cmdData?.computerPlayerId
    ? buildComputerPlayer(cmd)
    : buildHumanPlayer(cmd)

  if (prevGame.status && prevGame.status !== GameStatus.Ready) {
    throw new Error('Game can only be joined in Ready status')
  }
  const players: GamePlayer[] = [...prevGame.players]
  if (
    !players.find((previousPlayer) => previousPlayer.userId === player.userId)
  ) {
    players.push(player)
  }
  const game: Game = { ...prevGame, players }
  return { game, events: [] }
}

const getPlayerState: GameCmdHandler = (prevGame: Game, cmd: GameCmd) => {
  return { game: prevGame, events: [] }
}

const startGame: GameCmdHandler = (prevGame: Game, cmd: GameCmd) => {
  const hostUserId = cmd.player.userId
  if (prevGame.hostUserId && prevGame.hostUserId !== hostUserId) {
    throw new Error(
      `Game can not be started by nonhost, host was ${prevGame.hostUserId} vs user ${hostUserId}`,
    )
  }
  const game: Game = { ...prevGame, status: GameStatus.InProgress }
  return { game, events: [] }
}

const buildPlayersWithExitedPlayer = (
  prevGame: Game,
  exitingPlayerId: string,
  kickReason: string | null,
): GamePlayer[] => {
  const players = [...prevGame.players]
  const exitingPlayerIndex = players.findIndex(
    (player) => player.userId === exitingPlayerId,
  )
  if (exitingPlayerIndex !== -1) {
    const exitedPlayer: GamePlayer = {
      ...players[exitingPlayerIndex],
      didExit: true,
      kickReason: kickReason || null,
    }
    players.splice(exitingPlayerIndex, 1, exitedPlayer)
  }
  return players
}

const exitGame: GameCmdHandler = (prevGame: Game, cmd: GameCmd) => {
  const players = buildPlayersWithExitedPlayer(
    prevGame,
    cmd.player.userId,
    null,
  )
  const stillPlaying = players.filter((player) => !player.didExit)
  const game: Game = {
    ...prevGame,
    players,
    status: stillPlaying.length === 0 ? GameStatus.Abandoned : prevGame.status,
  }
  return { game, events: [] }
}

export type KickPlayerCmdData = {
  kickedPlayerId: string
  kickReason: string
}

const kickPlayer: GameCmdHandler = (prevGame: Game, cmd: GameCmd) => {
  if (!cmd.cmdData) {
    throw new Error('Can not kick player with empty KickPlayerCmdData')
  }
  const kickData = cmd.cmdData as KickPlayerCmdData
  if (cmd.player.userId !== prevGame.hostUserId) {
    throw new Error('only host can kick')
  }
  if (kickData.kickedPlayerId === prevGame.hostUserId) {
    throw new Error('Can not kick host')
  }
  const exitingUserId = kickData.kickedPlayerId
  const players = buildPlayersWithExitedPlayer(
    prevGame,
    exitingUserId,
    kickData.kickReason,
  )
  const game: Game = {
    ...prevGame,
    players,
  }
  return { game, events: [] }
}

export const baseHandlers = {
  createGame,
  startGame,
  joinGame,
  getPlayerState,
  kickPlayer,
  exitGame,
}
