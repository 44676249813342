import React, {
  FC,
  useState,
  useEffect,
  useCallback,
  MouseEventHandler,
} from 'react'
import { useLocation, useSearchParams, Location } from 'react-router-dom'
import {
  AnonGameCmd,
  Game,
  GameStatus,
  PlacedBid,
  PlayerScore,
  OhHellCmdType,
} from '@ohell/games'
import Roster, { RosterPlayer } from './Roster'
import Ready from './Ready'
import { OhHellPlayerState } from '@ohell/games'
import {
  GAME_MODE,
  getComputerPlayerRequest,
  HELP_MODE,
  HOST_MODE,
  OhHellGame,
  PanelMode,
} from './shared'
import InProgress from './InProgress'
import Complete from './Complete'
import { preWarmSTDDeck, preWarmSWDeck } from '../../services/DeckImages'
import {
  ConnectionContext,
  jwtSelector,
  messageSubscriptionsSelector,
  userSelector,
} from '../../services/Connections/connectionState'
import { GameSvc } from '../../services/GameSvc'
import {
  InboundMessage,
  MessageBusClient,
  buildMessageBusClientId,
  defaultChannels,
} from '../../services/Connections/socket'
import { Logger } from '../../shared/Logger'
import { NoProps } from '../../shared/types'
import Help from './Help'
import ChatBox from '../../widgets/ChatBox'
import HostActions from './HostActions'

type StateType = {
  gameId: string
  game?: OhHellGame
  rosterPlayers: RosterPlayer[]
  panelMode: PanelMode
  panelModeDetailId: string
}

const getRosterPlayers = (game: OhHellGame | undefined): RosterPlayer[] => {
  if (!game) {
    return []
  }
  const players = game.players
  const gameState = game.gameState as OhHellPlayerState | undefined
  if (gameState?.players) {
    const completedRound =
      gameState.completedRounds?.length > 0 &&
      gameState.completedRounds[gameState.completedRounds.length - 1]
    const round = gameState.currentRound || completedRound
    const bidInfoByPlayer = (round?.bids || []).reduce(
      (bidInfo: Record<string, PlacedBid>, bid: PlacedBid) => {
        bidInfo[bid.playerId] = bid
        return bidInfo
      },
      {} as Record<string, PlacedBid>,
    )
    const completeBidInfoByPlayer =
      (completedRound &&
        (completedRound?.bids || []).reduce(
          (bidInfo: Record<string, PlayerScore>, bid: PlayerScore) => {
            bidInfo[bid.playerId] = bid
            return bidInfo
          },
          {} as Record<string, PlayerScore>,
        )) ||
      {}
    return gameState.players.map((player) => ({
      handle: player.handle,
      isComputer: !!player.computerPlayerId,
      computerPlayerId: player.computerPlayerId,
      isHost: player.userId === game.hostUserId,
      userId: player.userId,
      isPlayerTurn: player.userId === round?.turnPlayerId,
      tricksBid: bidInfoByPlayer[player.userId]?.bidTricks,
      tricksWon: bidInfoByPlayer[player.userId]?.wonTricks,
      netScore: completeBidInfoByPlayer[player.userId]?.netScore,
      didExit: player.didExit,
      kickReason: player.kickReason,
    }))
  }
  return players.map((player) => ({
    handle: player.handle,
    isComputer: !!player.computerPlayerId,
    computerPlayerId: player.computerPlayerId,
    isHost: player.userId === game.hostUserId,
    userId: player.userId,
    didExit: player.didExit,
    kickReason: player.kickReason
  }))
}

const buildInitialState = (
  location: Location,
  searchParams: URLSearchParams,
): StateType => {
  const searchParamGameId = searchParams.get('gameId')
  const initialState: StateType = {
    ...(location.state as StateType),
    rosterPlayers: [],
    panelMode: GAME_MODE,
  }
  initialState.gameId = searchParamGameId || initialState.gameId
  initialState.rosterPlayers = getRosterPlayers(initialState.game)
  return initialState
}

const OhHell: FC<NoProps> = () => {
  const connection = ConnectionContext.useActorRef()
  const user = ConnectionContext.useSelector(userSelector)
  const jwt = ConnectionContext.useSelector(jwtSelector)
  const messageSubscriptions = ConnectionContext.useSelector(
    messageSubscriptionsSelector,
  )
  const location = useLocation()
  const [searchParams] = useSearchParams()
  const initialState = buildInitialState(location, searchParams)

  const [state, setState] = useState<StateType>(initialState)

  const gameId = state?.gameId
  const gameState = state.game?.gameState as OhHellPlayerState

  const gameMessageClientId = buildMessageBusClientId(
    defaultChannels.game,
    gameId,
  )

  const requestComputerMove = async (game: OhHellGame) => {
    const gameState = game.gameState
    Logger.debug('checking if computer play...')
    if (!game || !gameState?.currentRound?.turnPlayerId) {
      Logger.debug('checking if computer play...')
      return
    }
    Logger.debug('requesting computer play...')
    const cmd: AnonGameCmd | undefined = getComputerPlayerRequest(game)
    cmd && (await GameSvc.sendCmd(cmd, { jwt }))
  }

  const setStatusFromGame = useCallback(
    (game: Game) => {
      const ohGame = game as OhHellGame
      if (state.gameId && ohGame.gameId !== state.gameId) {
        return
      }
      const newState: StateType = {
        game: ohGame,
        gameId: ohGame.gameId,
        rosterPlayers: getRosterPlayers(ohGame),
        panelMode: state.panelMode,
        panelModeDetailId: '',
      }
      setState(newState)

      const nextTurnPlayer = ohGame.players.find(
        (player) =>
          player.userId === ohGame.gameState?.currentRound?.turnPlayerId,
      )
      if (
        user?.userId === ohGame?.hostUserId &&
        nextTurnPlayer?.computerPlayerId
      ) {
        const currentRound = ohGame?.gameState?.currentRound
        const isDealable: boolean =
          currentRound?.readyForCommands?.includes(OhHellCmdType.DealCards) ||
          false
        const isTrickEnded: boolean =
          currentRound?.completedTricks?.length > 0 &&
          !currentRound?.currentTrick?.cards.length
        const delayMs: number = isDealable || isTrickEnded ? 5000 : 500
        setTimeout(
          () =>
            requestComputerMove(ohGame).catch((e) =>
              Logger.debug(JSON.stringify(e)),
            ),
          delayMs,
        )
      } else {
        Logger.debug('no need to request computer play...')
      }
    },
    [setState, requestComputerMove],
  )

  const onGameMessage = useCallback(
    (message: InboundMessage) => {
      const game: Game | undefined = (message as { data: Game })?.data
      game && setStatusFromGame(game)
    },
    [setStatusFromGame],
  )

  useEffect(() => {
    preWarmSWDeck()
    preWarmSTDDeck()
    if (gameId && !state.game) {
      GameSvc.getGame(gameId, { jwt }).then((game) => {
        setStatusFromGame(game)
      })
    }
  })

  useEffect(() => {
    Logger.debug('subscribing to game')
    if (!messageSubscriptions.has(gameMessageClientId)) {
      const client: MessageBusClient = {
        clientId: gameMessageClientId,
        callback: onGameMessage,
        channels: [{ channel: defaultChannels.game, topic: gameId }],
      }
      connection.send({ type: 'SOCKET_SUBSCRIBE', value: client })
    }
  }, [gameMessageClientId])

  if (!gameId) {
    return (
      <div>
        <h1>oh weird</h1>
        <p>
          not sure what happened, but it's not clear which game you're trying to
          play
        </p>
        <p>maybe hit up the lobby, i guess</p>
      </div>
    )
  }

  const mainPanelToggleBuilder =
    (panelMode: PanelMode, panelModeDetailId?: string): MouseEventHandler =>
    (event) => {
      event.stopPropagation()
      setState({
        ...state,
        panelMode: state.panelMode === GAME_MODE ? panelMode : GAME_MODE,
        panelModeDetailId:
          state.panelMode === GAME_MODE ? panelModeDetailId || '' : '',
      })
    }
  const hostToggler = mainPanelToggleBuilder(HOST_MODE, '')
  const helpToggler = mainPanelToggleBuilder(HELP_MODE, '')

  const panelState: string | undefined =
    state.panelMode === HOST_MODE || state.panelMode === HELP_MODE
      ? state.panelMode
      : state?.game?.status

  const isGameMode = state.panelMode === GAME_MODE
  const isHelpMode = state.panelMode === HELP_MODE
  const isHostMode = state.panelMode === HOST_MODE

  return (
    <div className="container mx-auto mb-3 h-80v w-90v">
      <h1>Oh hell.</h1>
      {state.game && (
        <div className="md:flex mx-auto h-full w-full justify-center">
          <Roster
            isUserHost={true}
            players={state.rosterPlayers}
            status={state.game.status}
            doToggleHelp={mainPanelToggleBuilder(HELP_MODE)}
            doToggleHost={mainPanelToggleBuilder(HOST_MODE)}
          ></Roster>
          {isHelpMode && <Help doToggleHelp={helpToggler}></Help>}
          {isHostMode && (
            <HostActions
              doToggleHost={hostToggler}
              game={state.game}
            ></HostActions>
          )}
          {isGameMode && state.game.status === GameStatus.Ready && (
            <Ready game={state.game}></Ready>
          )}
          {panelState === GameStatus.InProgress && gameState.currentRound && (
            <InProgress game={state.game}></InProgress>
          )}
          {panelState === GameStatus.InProgress && !gameState.currentRound && (
            <Complete game={state.game}></Complete>
          )}
          <ChatBox
            chatRoomId={getChatRoomId(state.game)}
            chatroomName={getChatRoomChannel()}
          ></ChatBox>
        </div>
      )}
    </div>
  )
}
export default OhHell

function getChatRoomId(game: Game) {
  return game.gameId
}

function getChatRoomChannel() {
  return 'game chat'
}
